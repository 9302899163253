<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Hypochlorous acid
        <chemical-latex content=",HOCl," />
        has a
        <stemble-latex content="$\text{pK}_\text{a}$" />
        of
        <stemble-latex content="$7.530.$" />
        If a
        <number-value :value="volSoln" unit="\text{mL}" />
        solution of
        <number-value :value="concHOCl" unit="\text{M HOCl}" />
        is titrated with
        <number-value :value="concNaOH" unit="\text{M NaOH.}" />
        Calculate the
        <stemble-latex content="$\text{pH}$" />
        of the solution at each of the following points on the titration curve.
      </p>

      <p class="mb-2">
        <stemble-latex content="$\text{a) 0.00 mL of NaOH}$" />
      </p>
      <calculation-input
        v-model="inputs.pHa"
        prepend-text="$\text{pH:}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex :content="'$\\text{b) 10.00 mL of NaOH}$'" />
      </p>
      <calculation-input
        v-model="inputs.pHb"
        prepend-text="$\text{pH:}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex :content="'$\\text{c) At the equivalence point}$'" />
      </p>
      <calculation-input
        v-model="inputs.pHc"
        prepend-text="$\text{pH:}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex :content="'$\\text{d) 70.00 mL of NaOH}$'" />
      </p>
      <calculation-input
        v-model="inputs.pHd"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question149',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pHa: null,
        pHb: null,
        pHc: null,
        pHd: null,
      },
    };
  },
  computed: {
    volSoln() {
      return this.taskState.getValueBySymbol('volSoln').content;
    },
    concHOCl() {
      return this.taskState.getValueBySymbol('concHOCl').content;
    },
    concNaOH() {
      return this.taskState.getValueBySymbol('concNaOH').content;
    },
  },
};
</script>
